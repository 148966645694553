'use client'

import React, { useState } from "react";
import { Navbar, NavbarBrand, NavbarMenuToggle, NavbarMenuItem, NavbarMenu, NavbarContent, NavbarItem, Dropdown, DropdownMenu, DropdownItem, DropdownTrigger, Button } from "@nextui-org/react";
import { Typography } from "@mui/material";
import Link from 'next/link';

export default function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const openDropdown = () => {
    setDropdownOpen(true);
  }
  const closeDropdown = () => {
    setDropdownOpen(false);
  }

  return (

    <Navbar className="bg-background-opaq navbar text-navigation-text" isBlurred isMenuOpen={isMenuOpen} onMenuOpenChange={setIsMenuOpen} maxWidth="full" style={{ zIndex: '1001' }}>
      <NavbarBrand>
        <Link href="/" className="font-bold text-inherit brandtext" size="lg">
          Marlene Gujan Architektur AG
        </Link>
      </NavbarBrand>

      <NavbarContent className="hidden md:flex gap-4" justify="center">
      </NavbarContent>
      <NavbarContent className="hidden md:flex gap-4" justify="end">
        <NavbarItem>
          <Link href="/projekte" size="lg" className="font-bold ">
            Projekte
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link href="/werkliste" size="lg" className="font-bold">
            Werkliste
          </Link>
        </NavbarItem>
        <NavbarItem onMouseEnter={openDropdown} onMouseLeave={closeDropdown}>
          <Dropdown isOpen={isDropdownOpen}>
            <DropdownTrigger>
              <Button className="font-bold navbarbutton" auto ghost>
                Büro
              </Button>
            </DropdownTrigger>
            <DropdownMenu className="bg-background-opaq dropdown-navigation custom-dropdown-menu" aria-label="Büro Menu">
              <DropdownItem textValue="Philosophie">
                <Typography>
                  <Link color="primary" href="/buero/philosophie" className="font-bold block w-full text-navigation-text" size="lg">
                    Philosophie
                  </Link>
                </Typography>
              </DropdownItem>
              <DropdownItem textValue="Team">
                <Typography>
                  <Link color="primary" href="/buero/team" className="font-bold block w-full text-navigation-text" size="lg">
                    Team
                  </Link>
                </Typography>
              </DropdownItem>
              <DropdownItem textValue="Portrait">
                <Typography>
                  <Link color="primary" href="/buero/portrait" className="font-bold block w-full text-navigation-text" size="lg">
                    Portrait
                  </Link>
                </Typography>
              </DropdownItem>
              <DropdownItem textValue="Auszeichnungen">
                <Typography>
                  <Link color="primary" href="/buero/auszeichnungen" className="font-bold block w-full text-navigation-text" size="lg">
                    Auszeichnungen
                  </Link>
                </Typography>
              </DropdownItem>
              <DropdownItem textValue="Publikationen">
                <Typography>
                  <Link color="primary" href="/buero/publikationen" className="font-bold block w-full text-navigation-text" size="lg">
                    Publikationen
                  </Link>
                </Typography>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </NavbarItem>
        <NavbarItem>
          <Link href="/kontakt" className="font-bold" size="lg">
            Kontakt
          </Link>
        </NavbarItem>
      </NavbarContent>

      <NavbarContent className="md:hidden" justify="end">
        <NavbarMenuToggle aria-label={isMenuOpen ? "Close menu" : "Open menu"} />
      </NavbarContent>

      <NavbarMenu className="bg-background-opaq" style={{ marginTop: '1rem', paddingTop: '2.5rem', zIndex: '1001' }}>
        <NavbarMenuItem className="mobile-nav-item">
          <Typography color="primary">
            <Link color="foreground" href="/projekte" className="font-bold block w-full" size="lg">
              Projekte
            </Link>
          </Typography>
        </NavbarMenuItem>
        <NavbarMenuItem className="mobile-nav-item">
          <Typography color="primary">
            <Link color="foreground" href="/werkliste" className="font-bold block w-full" size="lg">
              Werkliste
            </Link>
          </Typography>
        </NavbarMenuItem>
        <NavbarMenuItem className="mobile-nav-item">
          <Typography color="primary">
            <Link color="foreground" href="/buero/philosophie" className="font-bold block w-full" size="lg">
              Philosophie
            </Link>
          </Typography>
        </NavbarMenuItem>
        <NavbarMenuItem className="mobile-nav-item">
          <Typography color="primary">
            <Link color="foreground" href="/buero/team" className="font-bold block w-full" size="lg">
              Team
            </Link>
          </Typography>
        </NavbarMenuItem>
        <NavbarMenuItem className="mobile-nav-item">
          <Typography color="primary">
            <Link color="foreground" href="/buero/portrait" className="font-bold block w-full" size="lg">
              Portrait
            </Link>
          </Typography>
        </NavbarMenuItem>
        <NavbarMenuItem className="mobile-nav-item">
          <Typography color="primary">
            <Link color="foreground" href="/buero/auszeichnungen" className="font-bold block w-full" size="lg">
              Auszeichnungen
            </Link>
          </Typography>
        </NavbarMenuItem>
        <NavbarMenuItem className="mobile-nav-item">
          <Typography color="primary">
            <Link color="foreground" href="/buero/publikationen" className="font-bold block w-full" size="lg">
              Publikationen
            </Link>
          </Typography>
        </NavbarMenuItem>
        <NavbarMenuItem className="mobile-nav-item">
          <Typography color="primary">
            <Link color="foreground" href="/kontakt" className="font-bold block w-full" size="lg">
              Kontakt
            </Link>
          </Typography>
        </NavbarMenuItem>
      </NavbarMenu>
    </Navbar>
  );
}

'use client'

import React from "react";
import { Card, CardBody } from "@nextui-org/react";
import Link from 'next/link';

export default function Footer() {
    const year = new Date().getFullYear();

    return (
        <footer className="footer" id="footer">
            <Card className="footer-card bg-primary-opaq" fullWidth radius="none">
                <CardBody className="items-center items-centered">
                    <div className="flex gap-4">
                        <Link href="/datenschutz" className="font-bold text-footer-text">Datenschutz</Link>
                        <p className="text-footer-text">&copy; Copyright {year}</p>
                        <Link href="/impressum" className="font-bold text-footer-text">Impressum</Link>
                    </div>
                </CardBody>
            </Card>
        </footer>
    );
}